<template>
  <div>
    <b-img v-if="images.length <= 1" style="width: 100%;" :src="images[0]"/>
    <viewer v-else class="gallery" :images="images">
      <img v-for="src in images" :key="src" :src="src">
    </viewer>
  </div>
</template>
<script>
import {common} from '@/utils';

export default {
  data() {
    return {
      images: [
        "/manual/EB全社組織図_20250114.png",
      ]
    }
  },
  created() {
    document.title = common.getPageName(this.$route.name);
  },
}
</script>
